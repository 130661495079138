import { ApartmentOutlined, FunctionOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSubscriptionData } from "../data/SubscriptionContext";
import "./Chat.scss";
import CreditControls from "./CreditControls";
import "./CreditControls.scss";
import CustomAudioRecorder from "./CustomAudioRecorder";
import PromptEditor, { FocusableRef } from "./PromptEditor";
import GlowingTooltip from "./GlowingTooltip";

import { usePrompt } from "../data/Prompt";

import { usePlatform } from "../data/PlatformContext";
import ImageInput from "./ImageInput";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { PlanningAnswer } from "../data/SolverProjects";
import {
    SessionStatus,
    Turn,
    useCanCancelSolve,
    useCanSolve,
    useCancelSolve,
    useComments,
    useCreateAndSolve,
    usePlan,
    useRevertToTurn,
    useSession,
    useSessionNLText,
    useSessionStatus,
    useSolve,
    useTurns,
} from "../data/SolverSession";
import { TutorialAction } from "../data/SolverInterfaceEvent";
import { useDropzone } from "react-dropzone";
import { deleteImageAttachment, uploadImageAttachment } from "../data/AttachmentStoreInterface";

interface NaturalLanguageInputProps {
    notification: NotificationInstance;
    editingTurn: Turn | null;
    onCancelEditingTurn: () => void;
    sessionType: "planning" | "solving";
    techPlanAnswers?: PlanningAnswer[];
}

// Maximum number of attachments allowed
const MAX_ATTACHMENTS = 1;

const NaturalLanguageInput = forwardRef<FocusableRef, NaturalLanguageInputProps>(
    ({ notification, editingTurn, onCancelEditingTurn, sessionType, techPlanAnswers }, ref) => {
        // Forward the focus capability to the parent
        useImperativeHandle(ref, () => ({
            focus: () => promptEditorRef.current?.focus(),
        }));
        const session = useSession();
        const sessionNLText = useSessionNLText();
        const sessionStatus = useSessionStatus();
        const solve = useSolve();
        const createAndSolve = useCreateAndSolve();
        const plan = usePlan();
        const canSolve = useCanSolve();
        const cancelSolve = useCancelSolve();
        const canCancelSolve = useCanCancelSolve();
        const comments = useComments();
        const revertToTurn = useRevertToTurn();
        const turns = useTurns();

        const { isTouchDevice, controlKey } = usePlatform();

        const { currentUser, activeRepo } = useSolverInterfaceContext();

        const { getNumSteps } = useSubscriptionData();

        const promptEditorRef = useRef<FocusableRef>(null);

        // Cache the prompt so that it can be updated when we solve.
        const [cachedNLText, setCachedNLText] = useState<string>("");
        const [attachments, setAttachments] = useState<UploadFile[]>([]);

        const [isUploadingImages, setIsUploadingImages] = useState(false);

        const { promptExceedsMaxLengthMessage, doesTestPromptExceedMaxLength } = usePrompt(
            cachedNLText,
            comments,
            techPlanAnswers || []
        );

        useEffect(() => {
            if (!!editingTurn) {
                setCachedNLText(editingTurn.nl_text);
                promptEditorRef.current?.focus();
            } else {
                setCachedNLText(sessionNLText);
            }
        }, [sessionNLText, editingTurn]);

        useEffect(() => {
            // Only focus the editor automatically if we're not on a touch device. Autofocusing
            // will annoyingly pop up the keyboard on touch devices.
            if (!isTouchDevice) {
                promptEditorRef.current?.focus();
            }
        }, [session?.session_id, isTouchDevice]);

        // Handle attachments storage and cleanup
        useEffect(() => {
            // Clear attachments when switching sessions or when session becomes ready
            if (sessionStatus === SessionStatus.READY || !session?.session_id) {
                setAttachments([]);
                // Clean up both session-specific and new session attachments
                if (session?.session_id) {
                    window.sessionStorage.removeItem(`attachments_${session.session_id}`);
                }
                window.sessionStorage.removeItem("attachments_new_session");
            }
        }, [sessionStatus, session?.session_id]);

        // Clear cached text when session changes or is deleted
        useEffect(() => {
            if (!session?.session_id) {
                setCachedNLText("");
            }
        }, [session?.session_id]);

        // Store attachments in session storage when they change
        useEffect(() => {
            // Always update storage to ensure it's in sync with state
            const storageKey = session?.session_id ? `attachments_${session.session_id}` : "attachments_new_session";
            if (attachments.length > 0) {
                // Store only essential metadata in session storage, not the full file content
                const storableAttachments = attachments.map((file) => ({
                    uid: file.uid,
                    name: file.name,
                    status: file.status,
                    type: file.type,
                    size: file.size,
                    response: file.response, // Contains the attachmentId from server
                }));

                try {
                    window.sessionStorage.setItem(storageKey, JSON.stringify(storableAttachments));
                } catch (error) {
                    console.error("Failed to store attachments:", error);
                    notification.warning({
                        message: "Session Storage Warning",
                        description:
                            "Some attachment metadata couldn't be saved to session storage. Your uploads will still work but may not persist if you reload the page.",
                        placement: "bottomRight",
                        duration: 5,
                    });
                }
            } else {
                // Clear storage when there are no attachments
                window.sessionStorage.removeItem(storageKey);
            }
        }, [attachments, session?.session_id, notification]);
        const onPromptChange = (value: string | undefined) => setCachedNLText(value || "");

        const handleTranscriptionComplete = async (transcribedText: string) => {
            setCachedNLText(transcribedText);

            // Give the user the opportunity amend the transcription if it's too long.
            if (doesTestPromptExceedMaxLength(transcribedText)) {
                return;
            }

            // Automatically submit the transcribed text
            if (!session) {
                if (activeRepo) {
                    createAndSolve(
                        transcribedText,
                        activeRepo.org,
                        activeRepo.name,
                        activeRepo.default_branch,
                        getNumSteps()
                    );
                } else {
                    throw new Error("No active repository selected");
                }
            } else {
                solve(transcribedText, comments, getNumSteps());
            }
        };

        const buildSolveAndOrPlanButtons = () => {
            if (sessionType === "planning") {
                return buildPlanButton();
            } else {
                return buildSolveButton();
            }
        };

        const buildButtons = () => {
            switch (sessionStatus) {
                case SessionStatus.READY:
                    return buildSolveAndOrPlanButtons();
                case SessionStatus.SUBMITTING_SOLVE:
                    return buildSubmittingSolveButton();
                case SessionStatus.PENDING:
                case SessionStatus.SOLVING:
                    return buildCancelSolveButton();
                case SessionStatus.SUBMITTING_CANCEL:
                    return buildCancellingSolveButton();
                default:
                    return buildSolveAndOrPlanButtons();
            }
        };

        const buildSolveButton = () => {
            const buttonText =
                comments.length > 0 && !editingTurn
                    ? `Solve with ${comments.length} comment${comments.length > 1 ? "s" : ""}`
                    : "Solve";

            const isDisabled =
                !canSolve(cachedNLText, comments, getNumSteps(), undefined) ||
                promptExceedsMaxLengthMessage.length > 0 ||
                isUploadingImages;

            return (
                <GlowingTooltip
                    configs={[
                        {
                            action: TutorialAction.EXPLAIN_SURCHARGE,
                            title: "Control the number of steps to solve your task. When sessions get longer, a surcharge may apply.",
                        },
                    ]}
                    placement="topLeft"
                >
                    <CreditControls
                        onSolve={onSolve}
                        disabled={isDisabled}
                        buttonText={buttonText}
                        icon={<FunctionOutlined />}
                    />
                </GlowingTooltip>
            );
        };

        const buildPlanButton = () => {
            const isDisabled =
                !canSolve(cachedNLText, [], getNumSteps(), techPlanAnswers) ||
                promptExceedsMaxLengthMessage.length > 0 ||
                isUploadingImages;

            return (
                <GlowingTooltip
                    configs={[
                        {
                            action: TutorialAction.EXPLAIN_SURCHARGE,
                            title: "Control the number of steps to solve your task. When sessions get longer, a surcharge may apply.",
                        },
                    ]}
                    placement="topLeft"
                >
                    <CreditControls
                        onSolve={onPlan}
                        disabled={isDisabled}
                        buttonText="Plan"
                        icon={<ApartmentOutlined />}
                    />
                </GlowingTooltip>
            );
        };

        const buildSubmittingSolveButton = () => {
            return (
                <Button className="solve-button" type="primary" disabled={true}>
                    <LoadingOutlined /> Submitting
                </Button>
            );
        };

        const buildCancelSolveButton = () => {
            return (
                <Button
                    className="solve-button"
                    onClick={(e) => {
                        onCancelSolve();
                        e.stopPropagation();
                    }}
                    disabled={!canCancelSolve}
                >
                    <LoadingOutlined /> Cancel
                </Button>
            );
        };

        const buildCancellingSolveButton = () => {
            return (
                <Button className="solve-button" disabled={true}>
                    <LoadingOutlined /> Cancelling
                </Button>
            );
        };

        const onSolve = async () => {
            if (!currentUser || !activeRepo) return;

            if (editingTurn) {
                try {
                    // When editing, first revert to the turn before the edited one
                    const prevTurn = turns.find((t) => t.idx === editingTurn.idx - 1);
                    await revertToTurn(prevTurn?.id ?? null);
                    // Then solve with the new text, but without any comments since we're just saving changes
                    await solve(cachedNLText, [], getNumSteps());
                    onCancelEditingTurn();
                } catch (error: unknown) {
                    console.error("Failed to edit turn:", error);
                    const errorMessage = error instanceof Error ? error.message : String(error);
                    notification.error({ message: "Failed to edit turn", description: errorMessage });
                }
                return;
            }

            try {
                if (!session) {
                    // Clear attachments list before creating session
                    await createAndSolve(
                        cachedNLText,
                        activeRepo.org,
                        activeRepo.name,
                        activeRepo.default_branch,
                        getNumSteps()
                    );
                    setAttachments([]); // Clear attachment list after creating session
                } else {
                    await solve(cachedNLText, comments, getNumSteps());
                    setAttachments([]); // Clear attachment list after solving
                }
            } catch (error) {
                notification.error({
                    message: "Failed to solve",
                    description: error instanceof Error ? error.message : String(error),
                });
            }
        };

        const onPlan = async () => {
            if (!currentUser || !activeRepo || !session) {
                return;
            }

            plan(cachedNLText, techPlanAnswers || [], getNumSteps());
        };

        const onCancelSolve = () => {
            if (!canCancelSolve) return;

            cancelSolve();
            setAttachments([]);
        };

        const onPromptEditorKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (processSolveHotkey(e)) return;
        };

        const processSolveHotkey = (e: React.KeyboardEvent<HTMLElement>): boolean => {
            if (
                (e.ctrlKey || e.metaKey) &&
                e.key === "Enter" &&
                canSolve(cachedNLText, comments, getNumSteps(), techPlanAnswers)
            ) {
                if (sessionType === "planning") {
                    onPlan();
                } else {
                    onSolve();
                }

                (e.target as HTMLTextAreaElement).blur();
                e.preventDefault();
                return true;
            }

            return false;
        };

        const buildPromptLimitExceededMessage = () => {
            if (promptExceedsMaxLengthMessage.length === 0) return null;

            return <div className="prompt-limit-exceeded-warning">{promptExceedsMaxLengthMessage}</div>;
        };

        const processImages = async (uploadFiles: UploadFile[]) => {
            try {
                // Filter out non-image files and files that are too large
                // Apply initial MIME type and size checks
                const imageFiles = uploadFiles.filter((file) => {
                    if (file.type && !file.type.startsWith("image/")) {
                        notification.error({
                            message: "Invalid File Type",
                            description: `File is not an image.`,
                            placement: "bottomRight",
                            key: "imageUploadNotification",
                            duration: 5,
                        });
                        return false;
                    }
                    // Check file size (5MB = 5 * 1024 * 1024 bytes)
                    if (file.size && file.size > 5 * 1024 * 1024) {
                        notification.error({
                            message: "File Too Large",
                            description: `File exceeds the 5MB size limit.`,
                            placement: "bottomRight",
                            key: "imageUploadNotification",
                            duration: 5,
                        });
                        return false;
                    }
                    return true;
                });

                // For any new files, validate and upload them immediately
                const latestFile = imageFiles[imageFiles.length - 1];
                if (latestFile?.originFileObj && activeRepo) {
                    try {
                        setIsUploadingImages(true);
                        // Start upload - uploadImageAttachment now includes content validation
                        const uploadResult = await uploadImageAttachment(
                            activeRepo.org,
                            activeRepo.name,
                            latestFile.originFileObj
                        );

                        // Update the file with the server-generated ID and store attachment info
                        latestFile.uid = uploadResult.attachmentId;
                        latestFile.status = "done";
                        // Store the full attachment information in the file's response
                        latestFile.response = uploadResult;
                    } catch (error) {
                        // Handle validation errors specifically
                        const errorMessage = error instanceof Error ? error.message : "Unknown error";

                        notification.error({
                            message: errorMessage.includes("Invalid") ? "Invalid File Format" : "Upload Failed",
                            description: errorMessage,
                            placement: "bottomRight",
                            key: "imageUploadNotification",
                            duration: 5,
                        });
                        // Remove the failed file from the list
                        imageFiles.pop();
                    } finally {
                        setIsUploadingImages(false);
                    }
                }

                setAttachments((prev) => {
                    // Calculate how many more files we can add
                    const remainingSlots = MAX_ATTACHMENTS - prev.length;
                    if (remainingSlots <= 0) {
                        notification.warning({
                            message: `Maximum ${MAX_ATTACHMENTS} files already attached.`,
                        });
                        return prev;
                    }

                    // Take only as many new files as we have slots for
                    const filesToAdd = imageFiles.slice(0, remainingSlots);
                    if (imageFiles.length > remainingSlots) {
                        notification.warning({
                            message: `Only ${remainingSlots} more file(s) can be added. Some files were skipped.`,
                        });
                    }

                    return [...prev, ...filesToAdd];
                });
            } catch (error) {
                notification.error({
                    message: "Upload Error",
                    description: "Failed to upload image. Please try again.",
                    placement: "bottomRight",
                    duration: 5,
                    key: "imageUploadNotification",
                });
            }
        };

        const onFilesDrop = (files: File[]) => {
            if (files.length === 0) return;

            const fileUploads = files.map(domFileToAntdUploadFile);

            processImages(fileUploads);
        };

        const { getRootProps } = useDropzone({ onDrop: onFilesDrop });

        const domFileToAntdUploadFile = (file: File): UploadFile => ({
            uid: Math.random().toString(),
            name: file.name,
            size: file.size,
            type: file.type,
            originFileObj: file as RcFile,
        });

        const onImagesRemoved = async (removedFiles: UploadFile[]) => {
            if (activeRepo) {
                removedFiles.forEach(async (file) => {
                    try {
                        if (file.response) {
                            await deleteImageAttachment(activeRepo.org, activeRepo.name, file.response);
                        }
                    } catch (error) {
                        notification.error({
                            message: "Delete Failed",
                            description: "Failed to delete image from server. Please try again.",
                            duration: 5,
                            placement: "bottomRight",
                            key: "imageDeleteNotification",
                        });
                    }
                });
            }

            setAttachments((prev) => prev.filter((file) => !removedFiles.some((removed) => removed.uid === file.uid)));
        };

        const explainFollowUpSuffix = useMemo(() => {
            if (controlKey) {
                return ` Press ${controlKey}+Enter to submit.`;
            } else {
                return "";
            }
        }, [controlKey]);

        const containerRootProps = getRootProps({
            onClick: (e) => {
                promptEditorRef.current?.focus();

                getRootProps().onClick?.(e);
            },
        });

        return (
            <GlowingTooltip
                configs={[
                    {
                        action: TutorialAction.EXPLAIN_FOLLOWUP,
                        title: `Type your next request here - ask Solver to make changes, explain code, or try new approaches.${explainFollowUpSuffix}`,
                    },
                ]}
                placement="topLeft"
            >
                <div className="nl-text-container" {...containerRootProps}>
                    <PromptEditor
                        ref={promptEditorRef}
                        value={cachedNLText}
                        onChange={onPromptChange}
                        onKeyDown={onPromptEditorKeyDown}
                        placeholder="Write a task, pose a problem, or ask a question"
                        disabled={session ? !session.allowModification(currentUser?.id) : false}
                        onPasteFiles={onFilesDrop}
                    />
                    <div className="solve-button-container">
                        {buildPromptLimitExceededMessage()}
                        {sessionStatus === SessionStatus.READY && (
                            <ImageInput
                                fileList={attachments}
                                uploading={isUploadingImages}
                                maxFiles={MAX_ATTACHMENTS}
                                onImagesAdded={processImages}
                                onImagesRemoved={onImagesRemoved}
                            />
                        )}
                        <div className="audio-recorder-container">
                            {(!session || session.allowModification(currentUser?.id)) && (
                                <CustomAudioRecorder onTranscriptionComplete={handleTranscriptionComplete} />
                            )}
                        </div>
                        {buildButtons()}
                    </div>
                </div>
            </GlowingTooltip>
        );
    }
);

export default NaturalLanguageInput;
