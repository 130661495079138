import React, { useState } from "react";

import { Button, Upload, Popover, Tooltip } from "antd";
import { DeleteOutlined, FileImageOutlined, LoadingOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";

import "./ImageInput.scss";

interface ImageInputProps {
    fileList: UploadFile[];
    uploading: boolean;
    maxFiles: number;
    onImagesAdded: (files: UploadFile[]) => void;
    onImagesRemoved: (files: UploadFile[]) => void;
}

const ImageInput: React.FC<ImageInputProps> = ({ fileList, uploading, maxFiles, onImagesAdded, onImagesRemoved }) => {
    const [thumbnailUrls, setThumbnailUrls] = useState<Record<string, string>>({});

    React.useEffect(() => {
        // Cleanup thumbnail URLs when component unmounts
        return () => {
            Object.values(thumbnailUrls).forEach((url) => URL.revokeObjectURL(url));
        };
    }, [thumbnailUrls]);

    const getThumbnailUrl = (file: UploadFile) => {
        if (file.url || file.thumbUrl) {
            return file.url || file.thumbUrl;
        }

        if (!thumbnailUrls[file.uid] && file.originFileObj) {
            const url = URL.createObjectURL(file.originFileObj);
            setThumbnailUrls((prev) => ({ ...prev, [file.uid]: url }));
            return url;
        }

        return thumbnailUrls[file.uid];
    };

    const renderPreview = (file: UploadFile) => (
        <div className="image-preview-popover">
            <img
                src={getThumbnailUrl(file)}
                alt={file.name}
                style={{ maxWidth: "600px", maxHeight: "600px", objectFit: "contain" }}
            />
        </div>
    );

    const handleChange: UploadProps["onChange"] = async ({ fileList: newFileList }) => {
        onImagesAdded(newFileList);
    };

    return (
        <div
            className="image-input"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {fileList.length > 0 && (
                <div className="image-pills">
                    {fileList.map((file) => (
                        <div key={file.uid} className="image-pill">
                            <Popover
                                content={renderPreview(file)}
                                trigger="click"
                                placement="top"
                                overlayStyle={{ padding: 0 }}
                            >
                                <Button type="primary" size="small">
                                    <div className="thumbnail-container">
                                        <img src={getThumbnailUrl(file)} alt="" className="preview-thumbnail" />
                                        <div
                                            className="delete-overlay"
                                            onClick={async (e) => {
                                                e.stopPropagation();

                                                onImagesRemoved([file]);
                                            }}
                                        >
                                            <DeleteOutlined />
                                        </div>
                                    </div>
                                    Image
                                </Button>
                            </Popover>
                        </div>
                    ))}
                </div>
            )}
            {fileList.length < maxFiles && (
                <Tooltip title="Include an image in your prompt" placement="top">
                    <Upload
                        showUploadList={false}
                        fileList={fileList}
                        // Note: need to remove this if we allow multiple file uploads.
                        multiple={false}
                        onChange={handleChange}
                        beforeUpload={() => false}
                        accept=".jpg,.jpeg,.png,.gif"
                        maxCount={maxFiles}
                        className="simple-upload"
                    >
                        {uploading ? (
                            <LoadingOutlined className="upload-icon" spin />
                        ) : (
                            <FileImageOutlined className="upload-icon" />
                        )}
                    </Upload>
                </Tooltip>
            )}
        </div>
    );
};

export default ImageInput;
