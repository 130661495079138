"use client";
import React, { useState } from "react";
import { Button, Progress, Typography, Spin } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import { ExecutionContent } from "../../data/TurnEventContent";
import { ReactComponent as StopButton } from "../../images/stop_button.svg";
import { theme } from "antd";
import { solverInterfaceApiAxios } from "../../data/SolverInterfaceConstants";
import "./Execution.scss";
import { useAppMenuState } from "../../data/AppMenuState";

import Terminal from "./Terminal";

interface ExecutionProps {
    executionContent: ExecutionContent;
    onInterrupt: () => Promise<boolean>;
}

const Execution: React.FC<ExecutionProps> = ({ executionContent, onInterrupt }) => {
    const { openExecutionSettings } = useAppMenuState();
    const { token } = theme.useToken();
    const [isInterrupting, setIsInterrupting] = useState(false);

    const handleInterrupt = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (isInterrupting) return;
        setIsInterrupting(true);
        try {
            await onInterrupt();
        } catch (error) {
            console.error("Failed to interrupt execution:", error);
            setIsInterrupting(false);
        }
    };

    const renderSetupState = () => {
        const setup = executionContent.setup;
        if (!setup || executionContent.completed || setup.completed) return null;

        if (setup.step === "image_pushing" || setup.step === "repo_pulling") {
            return (
                <div className="setup-state-container">
                    <Typography.Text>
                        <span>
                            {setup.step === "image_pushing"
                                ? "Waiting for the latest image to be available"
                                : "Getting the latest code"}
                        </span>
                        <span className="animated-ellipsis"></span>
                    </Typography.Text>
                </div>
            );
        }

        if (setup.step === "image_pulling" && setup.progress !== null) {
            return (
                <div className="setup-state-container">
                    <Typography.Text>Getting latest image</Typography.Text>
                    <Progress
                        percent={setup.progress}
                        showInfo={false}
                        strokeColor={token.colorPrimary}
                        status="active"
                    />
                </div>
            );
        }
    };

    return (
        <div className="execution-message-container">
            {renderSetupState()}
            <Terminal
                command={executionContent.command}
                stdout={executionContent.stdout}
                stderr={executionContent.stderr}
                exitCode={executionContent.exit_code}
                completed={executionContent.completed}
                error_msg={executionContent.error_msg}
            />
            {!executionContent.completed && (
                <div className="execution-controls">
                    <Button
                        className="active-session-controls-button"
                        icon={<StopButton style={{ width: "14px", height: "14px" }} />}
                        onClick={handleInterrupt}
                        disabled={isInterrupting}
                        loading={isInterrupting}
                    >
                        Interrupt Execution
                    </Button>
                </div>
            )}
            {!executionContent.exec_enabled && (
                <div className="message-cta-container">
                    <div className="message-cta-container">
                        <Button icon={<SettingOutlined />} onClick={openExecutionSettings} className="message-cta">
                            Configure Execution Settings
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Execution;
