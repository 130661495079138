import { Button, Layout, Modal, notification, Radio, Tooltip, Typography } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppMenuState } from "../data/AppMenuState";
import { ExecutionImageType, useExecutionSettingsContext } from "../data/ExecutionSettings";
import { useSolverInterfaceContext, ViewMode } from "../data/SolverInterface";
import {
    ExecutionStatus,
    LoadingProjectState,
    PlanningAnswer,
    useCanCancelProjectExecution,
    useCancelProjectExecution,
    useCanDeleteProjectExecution,
    useCanExecuteProject,
    useDeleteProjectExecution,
    useEvaluateTaskSession,
    useExecutionDisabledMessage,
    useExecuteProject,
    useExecutionGraph,
    useExecutionStatus,
    useLoadingProjectState,
    usePlanningSessionStatus,
    useProject,
} from "../data/SolverProjects";
import { SessionInfo, sessionIsLoading, Turn, useLoadSession } from "../data/SolverSession";
import { useSubscriptionData } from "../data/SubscriptionContext";
import CreditControls from "./CreditControls";
import "./CreditControls.scss";
import NaturalLanguageInput from "./NaturalLanguageInput";
import ProjectExecutionView from "./ProjectExecutionView";
import ReportButton from "./ReportButton";
import { PlanningView } from "./tech-plan/PlanningView";

import ActiveSessionTitle from "./ActiveSessionTitle";

import { LoadingOutlined, PlayCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { NavigationBehavior } from "../data/Navigation";
import { usePlatform } from "../data/PlatformContext";
import "./ActiveProject.scss";
import "./ActiveSession.scss";
import Chat from "./Chat";

const { Content } = Layout;

interface ActiveProjectProps {}

const ActiveProject: React.FC<ActiveProjectProps> = () => {
    const project = useProject();
    const executionGraph = useExecutionGraph();
    const executionStatus = useExecutionStatus();
    const loadingProjectState = useLoadingProjectState();
    const planningSessionStatus = usePlanningSessionStatus();
    const evaluateTaskSession = useEvaluateTaskSession();
    const canExecuteProject = useCanExecuteProject();
    const executionDisabledMessage = useExecutionDisabledMessage();
    const executeProject = useExecuteProject();
    const canCancelProjectExecution = useCanCancelProjectExecution();
    const cancelProjectExecution = useCancelProjectExecution();
    const canDeleteProjectExecution = useCanDeleteProjectExecution();
    const deleteProjectExecution = useDeleteProjectExecution();

    const { currentExecutionImage } = useExecutionSettingsContext();
    const { openExecutionSettings } = useAppMenuState();

    const loadSession = useLoadSession();
    const { setViewMode } = useSolverInterfaceContext();
    const { scrollbarStableBothClasses } = usePlatform();
    const { getNumSteps } = useSubscriptionData();

    const projectContentRef = useRef<HTMLDivElement>(null);

    const [notifcationApi, notificationContextHolder] = notification.useNotification();
    const [modalApi, modalContextHolder] = Modal.useModal();

    const [projectViewMode, setProjectViewMode] = useState<"chat" | "tech_plan" | "execution" | "photo">("chat");
    const [planningAnswers, setPlanningAnswers] = useState<PlanningAnswer[]>([]);
    const [editingTurn, setEditingTurn] = useState<Turn | null>(null);
    const [didFirstGraphDependentRender, setDidFirstGraphDependentRender] = useState(false);

    // If we ever delete the execution graph, we should go back to tech_plan mode.
    // On mount (and after we've loaded the graph), auto-switch to execution mode if there's an execution.
    useEffect(() => {
        if (!executionGraph) {
            setProjectViewMode("chat");
            return;
        }
        if (!didFirstGraphDependentRender) {
            setProjectViewMode("execution");
            setDidFirstGraphDependentRender(true);
        }
    }, [executionGraph, didFirstGraphDependentRender]);

    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            if (e.shiftKey && (e.metaKey || e.ctrlKey) && e.key === "p") {
                setProjectViewMode("photo");
                // The body's overflow is normally set to hidden due to Safari adding
                // 1 pixel sometimes. We need to set it to allow scrolling in photo mode
                document.getElementsByTagName("body")[0].style.overflow = "scroll";
            }
        });

        return () => {
            window.removeEventListener("keydown", (e) => {
                if (e.shiftKey && (e.metaKey || e.ctrlKey) && e.key === "p") {
                    setProjectViewMode("photo");
                }
            });
        };
    }, []);

    const buildViewModeSelector = () => (
        <Radio.Group
            size="small"
            value={projectViewMode}
            onChange={(e) => setProjectViewMode(e.target.value)}
            optionType="button"
        >
            <Radio.Button className="active-session-controls-radio" value="chat">
                Chat
            </Radio.Button>
            <Radio.Button className="active-session-controls-radio" value="tech_plan">
                Tech Plan
            </Radio.Button>
            <Radio.Button className="active-session-controls-radio" value="execution" disabled={!executionGraph}>
                Execution
            </Radio.Button>
        </Radio.Group>
    );

    const projectContent = () => {
        switch (projectViewMode) {
            case "execution":
                // TODO: maybe we allow users to go to this view, but show a message
                // that they need to plan first.
                if (!executionGraph) {
                    return null;
                }

                return (
                    <div className="execution-view">
                        <ProjectExecutionView
                            executionGraph={executionGraph}
                            canDeleteProjectExecution={canDeleteProjectExecution}
                            isDeletingProjectExecution={executionStatus === ExecutionStatus.SUBMITTING_DELETE}
                            onDeleteProjectExecution={deleteProjectExecution}
                            onClickTaskSession={(sessionInfo: SessionInfo) => {
                                setViewMode(ViewMode.SESSIONS);

                                loadSession(sessionInfo, NavigationBehavior.PUSH);
                            }}
                            onEvaluateTaskSession={async (projectTaskId: string, sessionId: string) => {
                                const success = await evaluateTaskSession(projectTaskId, sessionId);

                                if (!success) {
                                    notifcationApi.error({
                                        message: "Error",
                                        description: "Failed to submit evaluation",
                                        placement: "bottomRight",
                                    });
                                }

                                return success;
                            }}
                            notification={notifcationApi}
                            modal={modalApi}
                        />
                    </div>
                );
            case "chat":
                return (
                    <Chat
                        ref={projectContentRef}
                        editingTurn={editingTurn}
                        setEditingTurn={setEditingTurn}
                        // TODO: implement scroll position restoration.
                        restoreScrollPosition={() => {}}
                        onShowChangesView={() => setProjectViewMode("tech_plan")}
                        notification={notifcationApi}
                    />
                );
            case "tech_plan":
            case "photo":
            default:
                return (
                    <PlanningView
                        isLoading={planningSessionStatus && sessionIsLoading(planningSessionStatus)}
                        onAnswersChange={setPlanningAnswers}
                    />
                );
        }
    };

    const buildWelcomeMessage = () => {
        const newSessionMessageClasses = classNames("new-session-message", scrollbarStableBothClasses, {
            // Note: this should be conditioned on having suggestions if/when we
            // support prompt suggestions for projects.
            "new-session-message-centered": true,
        });

        return (
            <Layout className="session-chat-layout">
                <div className={newSessionMessageClasses}>
                    <div className="welcome-message-container">
                        <div className="new-session-solver-logo" />
                        <p className="welcome-message-line">Create a project to begin</p>
                    </div>
                </div>
                {notificationContextHolder}
            </Layout>
        );
    };

    const buildProjectContent = () => {
        if (!project) {
            return null;
        }

        const layoutClass = classNames({
            "session-full-height-layout": projectViewMode === "photo",
            "session-chat-layout": true,
        });

        return (
            <Layout className={layoutClass}>
                <div className="active-session-title-bar">
                    <ActiveSessionTitle
                        sessionTitle={project.name}
                        editable={false}
                        onUpdateTitle={async () => Promise.resolve(true)}
                        notification={notifcationApi}
                    />

                    <div className="active-session-controls-container">
                        <div className="active-session-controls">
                            {buildButton()}
                            <ReportButton
                                sessionInfo={{
                                    ...project.getInfo(),
                                    session_id: project.planning_session_id,
                                }}
                                className="active-session-controls-button active-session-controls-button-small"
                                notification={notifcationApi}
                                origin="project_controls"
                            />
                        </div>
                        <div className="active-session-controls-divider" />
                        <div className="active-session-controls">{buildViewModeSelector()}</div>
                    </div>
                </div>
                <div className={`session-content ${scrollbarStableBothClasses}`} ref={projectContentRef}>
                    {projectContent()}
                </div>
                {projectViewMode !== "execution" && !executionGraph && (
                    <div className="input-container">
                        <NaturalLanguageInput
                            notification={notifcationApi}
                            editingTurn={null}
                            onCancelEditingTurn={() => {}}
                            sessionType={"planning"}
                            techPlanAnswers={planningAnswers}
                        />
                    </div>
                )}
                {notificationContextHolder}
                {modalContextHolder}
            </Layout>
        );
    };

    const buildButton = () => {
        if (!executionStatus) {
            return buildExecuteButton();
        }

        switch (executionStatus) {
            case ExecutionStatus.READY:
                return buildExecuteButton();
            case ExecutionStatus.RUNNING:
                return buildCancelButton();
            case ExecutionStatus.SUBMITTING_EXECUTION:
                return buildSubmittingExecutionButton();
            case ExecutionStatus.CANCELLING:
            case ExecutionStatus.SUBMITTING_CANCEL:
                return buildCancellingButton();
        }
    };

    const handleClickExecute = async () => {
        executeProject(getNumSteps());
        setProjectViewMode("execution");
    };

    const isExecutionEnvironmentConfigured = useMemo(() => {
        return currentExecutionImage.type !== ExecutionImageType.None;
    }, [currentExecutionImage]);

    const buildExecuteButton = () => {
        const button = (
            // For some reason to get this tooltip to work, it needs to be wrapped in an empty div.
            <Tooltip title={executionDisabledMessage} placement="bottom">
                <div>
                    <CreditControls
                        disabled={!canExecuteProject || !isExecutionEnvironmentConfigured}
                        onSolve={handleClickExecute}
                        icon={<PlayCircleOutlined />}
                        buttonText={"Execute"}
                        labelSuffix={"per task"}
                        omitSurcharge={true}
                    />
                </div>
            </Tooltip>
        );
        if (!isExecutionEnvironmentConfigured) {
            return (
                <span className="environment-configuration-required">
                    <Typography.Text
                        className="environment-configuration-required-text"
                        onClick={openExecutionSettings}
                    >
                        Configure an environment to execute
                    </Typography.Text>
                    {button}
                </span>
            );
        } else {
            return button;
        }
    };

    const buildCancelButton = () => {
        return (
            <Button
                className="active-session-controls-button"
                disabled={!canCancelProjectExecution}
                onClick={cancelProjectExecution}
                icon={<LoadingOutlined />}
            >
                Cancel
            </Button>
        );
    };

    const buildSubmittingExecutionButton = () => {
        return (
            <Button
                className="active-session-controls-button active-session-controls-button-primary"
                disabled={true}
                icon={<LoadingOutlined />}
            >
                Submitting
            </Button>
        );
    };

    const buildCancellingButton = () => {
        return (
            <Button className="active-session-controls-button" disabled={true} loading={true}>
                Cancelling
            </Button>
        );
    };

    switch (loadingProjectState) {
        case LoadingProjectState.ERROR:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Failed to load project</span>
                </Content>
            );
        case LoadingProjectState.NOT_FOUND:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Project not found</span>
                </Content>
            );
        case LoadingProjectState.LOADING:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">
                        <LoadingOutlined style={{ fontSize: "40px" }} />
                    </span>
                </Content>
            );
        case LoadingProjectState.DONE:
            if (!project) {
                return buildWelcomeMessage();
            } else {
                return buildProjectContent();
            }
    }
};

export default ActiveProject;
