"use client";
import React, { useState } from "react";

import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import { Badge, Button, Popover } from "antd";

import { RocketOutlined } from "@ant-design/icons";
import { NavigationBehavior } from "../data/Navigation";
import { Repo } from "../data/Repos";
import { useSolverInterfaceContext, ViewMode } from "../data/SolverInterface";
import { useAppMenuState } from "../data/AppMenuState";
import { useLoadSession } from "../data/SolverSession";
import { useLoadProject } from "../data/SolverProjects";
import { AuthType } from "../data/User";
import AppMenu from "./AppMenu";
import MemoryStore from "./MemoryStore";
import NotificationModal from "./NotificationModal";
import FAQModal from "./FAQModal";
import NewRepoModal from "./NewRepoModal";
import RepoDropdown from "./RepoDropdown";
import SolverLogo from "./SolverLogo";
import { TutorialAction } from "../data/SolverInterfaceEvent";
import GlowingTooltip from "./GlowingTooltip";
import { useSubscriptionData } from "../data/SubscriptionContext";

import { ReactComponent as RocketPlus } from "../images/rocket_plus.svg";

interface SolverHeaderProps {
    onOpenSessionsList: () => void;
}

const SolverHeader: React.FC<SolverHeaderProps> = ({ onOpenSessionsList }) => {
    const loadSession = useLoadSession();
    const loadProject = useLoadProject();
    const {
        currentUser,
        loggedIn,
        repos,
        activeRepo,
        loadingAdditionalRepos,
        setActiveRepo,
        activateRepo,
        notifications,
        viewMode,
    } = useSolverInterfaceContext();
    const { isAppMenuOpen, setIsAppMenuOpen } = useAppMenuState();
    const { subscriptionData, generateUpgradeUrl } = useSubscriptionData();

    const [faqOpen, setFaqOpen] = useState<boolean>(false);
    const [memoryStoreOpen, setMemoryStoreOpen] = useState<boolean>(false);
    const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
    const [repoDropdownOpen, setRepoDropdownOpen] = useState<boolean>(false);
    const [isActivatingRepo, setIsActivatingRepo] = useState<Repo | undefined>(undefined);
    const [newRepoModalOpen, setNewRepoModalOpen] = useState<boolean>(false);
    const [generatingUpgradeUrl, setGeneratingUpgradeUrl] = useState<boolean>(false);

    const setActiveRepoAndNavigate = async (repo: Repo) => {
        if (repo.full_name === activeRepo?.full_name) {
            setRepoDropdownOpen(false);
            return;
        }

        if (!repo.is_activated) {
            setIsActivatingRepo(repo);
            const activated = await activateRepo(repo);
            setIsActivatingRepo(undefined);

            if (!activated) {
                return;
            }
        }

        setActiveRepo(repo, NavigationBehavior.PUSH);
        loadSession(undefined, NavigationBehavior.NONE);
        loadProject(undefined, NavigationBehavior.NONE);
        setRepoDropdownOpen(false);
    };

    const buildAppMenuButton = () => {
        return (
            <GlowingTooltip
                configs={[
                    {
                        action: TutorialAction.HIGHLIGHT_SETTINGS,
                        title: "Access your preferences, execution settings, memories, subscription, and more.",
                    },
                    {
                        action: TutorialAction.HIGHLIGHT_SUBSCRIPTION,
                        followUp: TutorialAction.HIGHLIGHT_SUBSCRIPTION_MENU_OPTION,
                        title: "Manage your subscription, including upgrading to Pro.",
                    },
                ]}
                placement="bottomLeft"
            >
                <Popover
                    content={
                        <AppMenu
                            onOpenFaq={() => setFaqOpen(true)}
                            onOpenMemoryStore={() => setMemoryStoreOpen(true)}
                            onOpenNotifications={() => setNotificationsOpen(true)}
                            onOpenSessionsList={onOpenSessionsList}
                            haveNotifications={notifications.length > 0}
                        />
                    }
                    open={isAppMenuOpen}
                    trigger="click"
                    arrow={false}
                    onOpenChange={setIsAppMenuOpen}
                    placement="bottomRight"
                >
                    <Button className="app-menu-trigger" onClick={() => setIsAppMenuOpen(!isAppMenuOpen)}>
                        {notifications.length > 0 ? (
                            <Badge className="notification-badge" dot={true} size="small">
                                <MenuOutlined />
                            </Badge>
                        ) : (
                            <MenuOutlined />
                        )}
                    </Button>
                </Popover>
            </GlowingTooltip>
        );
    };

    const onUpgradeSubscription = async () => {
        setGeneratingUpgradeUrl(true);
        try {
            const upgradeUrl = await generateUpgradeUrl();
            if (!upgradeUrl) return;

            window.location.href = upgradeUrl;
        } catch (error) {
            console.error("Error generating upgrade URL:", error);
        } finally {
            setGeneratingUpgradeUrl(false);
        }
    };

    const buildUpgradeSubscriptionButton = () => {
        if (!subscriptionData) return null;
        if (!subscriptionData.is_free) return null;

        return (
            <Button
                className="upgrade-subscription-button"
                type="primary"
                disabled={generatingUpgradeUrl}
                onClick={onUpgradeSubscription}
            >
                <div className="upgrade-subscription-button-inner">
                    {generatingUpgradeUrl ? <LoadingOutlined /> : <RocketPlus width={15} height={15} fill="white" />}
                    <span
                        // This element intentionally has no content so it can be set
                        // in CSS using ::before + content to control for responsive sizes.
                        className="upgrade-subscription-button-text"
                    />
                </div>
            </Button>
        );
    };

    const buildHeaderActions = () => {
        return (
            <div className="header-action-bar">
                {loggedIn && buildUpgradeSubscriptionButton()}
                {loggedIn && (
                    <GlowingTooltip
                        configs={[
                            {
                                action: TutorialAction.CONNECT_YOUR_REPO,
                                title: "When you're ready, connect your own repository by clicking here and installing the GitHub app.",
                            },
                        ]}
                        placement="bottomLeft"
                    >
                        <RepoDropdown
                            activeRepo={activeRepo}
                            repos={repos}
                            onSelectRepo={setActiveRepoAndNavigate}
                            loading={loadingAdditionalRepos}
                            isActivatingRepo={isActivatingRepo}
                            isDropdownOpen={repoDropdownOpen}
                            setDropdownOpen={setRepoDropdownOpen}
                            onAddRepo={() => setNewRepoModalOpen(true)}
                            authType={currentUser?.auth_type}
                        />
                    </GlowingTooltip>
                )}
                {currentUser && buildAppMenuButton()}
            </div>
        );
    };

    return (
        <>
            <div className="solver-header">
                <SolverLogo
                    onClick={() => {
                        switch (viewMode) {
                            case ViewMode.SESSIONS:
                                loadSession(undefined, NavigationBehavior.PUSH);
                                break;
                            case ViewMode.PROJECTS:
                                loadProject(undefined, NavigationBehavior.PUSH);
                                break;
                        }
                    }}
                />
                {buildHeaderActions()}
            </div>

            <MemoryStore open={memoryStoreOpen} onClose={() => setMemoryStoreOpen(false)} />
            <NotificationModal
                open={notificationsOpen}
                onClose={() => setNotificationsOpen(false)}
                notifications={notifications}
            />
            <FAQModal open={faqOpen} onClose={() => setFaqOpen(false)} />
            <NewRepoModal
                open={newRepoModalOpen}
                onClose={() => setNewRepoModalOpen(false)}
                authType={currentUser?.auth_type || AuthType.GitHub}
            />
        </>
    );
};

export default SolverHeader;
