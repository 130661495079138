import React, { useEffect, useRef, useState } from "react";

import { CameraOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Modal, notification, Radio } from "antd";

import classNames from "classnames";
import html2canvas from "html2canvas";
import { AuthType } from "../data/User";
import NewRepoWizard from "./NewRepoWizard";

import { NavigationBehavior } from "../data/Navigation";
import { getPromptSuggestions, PromptSuggestion } from "../data/Repos";
import { useSolverInterfaceContext, ViewMode } from "../data/SolverInterface";
import {
    LoadingSessionState,
    Session,
    sessionIsLoading,
    SessionStatus,
    Turn,
    useCreateAndSolve,
    useLoadingSessionState,
    useLoadSession,
    useRefreshTurns,
    useSession,
    useSessionStatus,
    useSolve,
    useTurns,
    useUpdateSessionTitle,
} from "../data/SolverSession";
import ActiveSessionControls from "./ActiveSessionControls";
import ActiveSessionTitle from "./ActiveSessionTitle";
import ChangesView from "./ChangesView";
import Chat from "./Chat";
import NaturalLanguageInput from "./NaturalLanguageInput";
import { FocusableRef } from "./PromptEditor";
import PromptSuggestionChips from "./PromptSuggestionChips";

const { Content } = Layout;

import { usePlatform } from "../data/PlatformContext";
import { useLoadProject } from "../data/SolverProjects";
import { useSubscriptionData } from "../data/SubscriptionContext";
import "../responsive.scss";
import "./ActiveSession.scss";
import { TutorialAction } from "../data/SolverInterfaceEvent";
import GlowingTooltip from "./GlowingTooltip";

interface ActiveSessionProps {}

const ActiveSession: React.FC<ActiveSessionProps> = () => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const { activeRepo, currentUser, repos, setViewMode } = useSolverInterfaceContext();
    const { isMobileDevice, scrollbarStableBothClasses } = usePlatform();
    const session = useSession();
    const sessionStatus = useSessionStatus();
    const turns = useTurns();
    const loadingSessionState = useLoadingSessionState();
    const refreshTurns = useRefreshTurns();
    const updateSessionTitle = useUpdateSessionTitle();
    const loadProject = useLoadProject();
    const createAndSolve = useCreateAndSolve();
    const solve = useSolve();

    const { getNumSteps } = useSubscriptionData();

    const sessionContentRef = useRef<HTMLDivElement>(null);

    const [modalApi, modalContextHolder] = Modal.useModal();

    const [sessionViewMode, setSessionViewMode] = useState<"chat" | "changes" | "photo">("chat");
    // TODO: When we add navigation, we should add the scroll position to the state
    // object in history.pushState calls. This way it will be preserved in between active
    // sessions.
    const [chatScrollPosition, setChatScrollPosition] = useState<number>(0);
    const [changesScrollPosition, setChangesScrollPosition] = useState<number>(0);
    const [promptSuggestions, setPromptSuggestions] = useState<PromptSuggestion[]>([]);
    const [editingTurn, setEditingTurn] = useState<Turn | null>(null);
    const [repoWizardModalOpen, setRepoWizardModalOpen] = useState(false);

    // Reset viewMode to "chat" when the active session changes
    useEffect(() => {
        setSessionViewMode("chat");
        setChatScrollPosition(0);
        setChangesScrollPosition(0);
    }, [session?.session_id]);

    useEffect(() => {
        if (sessionStatus === SessionStatus.SOLVING || sessionStatus === SessionStatus.PENDING) {
            setSessionViewMode("chat");
        }
    }, [sessionStatus]);

    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            if (e.shiftKey && (e.metaKey || e.ctrlKey) && e.key === "p") {
                setSessionViewMode("photo");
                // The body's overflow is normally set to hidden due to Safari adding
                // 1 pixel sometimes. We need to set it to allow scrolling in photo mode
                document.getElementsByTagName("body")[0].style.overflow = "scroll";
            }
        });

        return () => {
            window.removeEventListener("keydown", (e) => {
                if (e.shiftKey && (e.metaKey || e.ctrlKey) && e.key === "p") {
                    setSessionViewMode("photo");
                }
            });
        };
    }, []);

    // Fetch prompt suggestions when the active repo changes
    useEffect(() => {
        if (activeRepo && (!session || turns.length === 0)) {
            getPromptSuggestions(activeRepo.org, activeRepo.name)
                .then((suggestions) => {
                    setPromptSuggestions(suggestions);
                })
                .catch(() => {
                    setPromptSuggestions([]);
                });
        } else {
            setPromptSuggestions([]);
        }
    }, [activeRepo, session, turns]);

    const nlInputRef = useRef<FocusableRef>(null);

    const solveSuggestion = (suggestion: string | null) => {
        if (!activeRepo) {
            notificationApi.error({
                message: "Cannot create session",
                description: "Repository information is incomplete.",
                placement: "bottomRight",
            });
            return;
        }

        if (suggestion !== null) {
            // If we're in an empty session (session exists but no turns), use solve()
            if (session && turns.length === 0) {
                solve(suggestion, [], getNumSteps());
            } else {
                // Otherwise create a new session and solve
                createAndSolve(suggestion, activeRepo.org, activeRepo.name, activeRepo.default_branch, getNumSteps());
            }
        } else {
            // For null suggestions, focus the input for manual entry
            nlInputRef.current?.focus();
        }
    };

    const handleScreenshot = () => {
        html2canvas(document.getElementById("root") as HTMLElement, { useCORS: true }).then((canvas) => {
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = "screenshot.png";
            link.click();
        });
    };

    const sessionContent = () => {
        switch (sessionViewMode) {
            case "changes":
                return (
                    <ChangesView
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, changesScrollPosition);
                        }}
                    />
                );
            case "chat":
            case "photo":
            default:
                return (
                    <Chat
                        ref={sessionContentRef}
                        editingTurn={editingTurn}
                        setEditingTurn={setEditingTurn}
                        restoreScrollPosition={() => {
                            sessionContentRef.current?.scrollTo(0, chatScrollPosition);
                        }}
                        onShowChangesView={() => setSessionViewMode("changes")}
                        notification={notificationApi}
                    />
                );
        }
    };

    if (repos.length === 0) {
        return <Content className="no-active-session" />;
    }

    const buildWelcomeMessage = () => {
        const newSessionMessageClasses = classNames("new-session-message", scrollbarStableBothClasses, {
            "new-session-message-centered": promptSuggestions.length === 0,
        });

        const welcomeMessageContainerClasses = classNames("welcome-message-container", {
            "welcome-message-container-small": promptSuggestions.length > 0,
        });

        const isDemoRepo = activeRepo?.is_demo || false;

        return (
            <Layout className="session-chat-layout">
                <div className={newSessionMessageClasses}>
                    <div className={welcomeMessageContainerClasses}>
                        <div className="new-session-solver-logo" />
                        {isDemoRepo && (
                            <>
                                <h2 className="welcome-message-title">Start Building with Solver Instantly</h2>
                                <p className="welcome-message-description">
                                    Welcome to Solver’s Sandbox—your space to create, tinker, and explore. No setup
                                    needed—just grab your work as a zip file when you're done.
                                </p>
                            </>
                        )}
                    </div>
                    <PromptSuggestionChips
                        promptSuggestions={promptSuggestions}
                        onClick={(suggestion) => solveSuggestion(suggestion)}
                        isDemo={activeRepo?.is_demo}
                    />
                    {isDemoRepo && (
                        <>
                            <div className="separator">Ready to leave the sandbox?</div>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setRepoWizardModalOpen(true)}
                                className="connect-repo-button"
                            >
                                Connect a Repository
                            </Button>
                        </>
                    )}
                </div>
                <div className="input-container">
                    <NaturalLanguageInput
                        ref={nlInputRef}
                        notification={notificationApi}
                        editingTurn={editingTurn}
                        onCancelEditingTurn={() => setEditingTurn(null)}
                        sessionType={"solving"}
                    />
                </div>
                {contextHolder}
                <Modal
                    title="Connect a Repository"
                    open={repoWizardModalOpen}
                    onCancel={() => setRepoWizardModalOpen(false)}
                    footer={null}
                    width={600}
                >
                    <NewRepoWizard authType={currentUser?.auth_type || AuthType.GitHub} />
                </Modal>
            </Layout>
        );
    };

    const buildSessionContent = () => {
        if (!session) {
            return null;
        }

        const layoutClass = classNames({
            "session-full-height-layout": sessionViewMode === "photo",
            "session-chat-layout": true,
        });

        const sessionTitle = session ? session.title : "New Session";

        const onClickProjectLink = () => {
            if (!session.project_id) {
                return;
            }

            const sessionInfo = session.getInfo();
            const org = sessionInfo.org;
            const repo = sessionInfo.repo;
            const project_id = session.project_id;
            const projectInfo = { org, repo, project_id };

            setViewMode(ViewMode.PROJECTS);
            loadProject(projectInfo, NavigationBehavior.PUSH);
        };

        const isProjectTaskSession = (sess: Session) => {
            // Sorry, typescript equality makes no sense to me
            return !!sess.project_task_id;
        };

        return (
            <Layout className={layoutClass}>
                <div className="active-session-title-bar">
                    <ActiveSessionTitle
                        sessionTitle={sessionTitle}
                        editable={session.allowModification(currentUser?.id)}
                        onUpdateTitle={async (title) => {
                            if (session) {
                                return updateSessionTitle(title);
                            } else {
                                console.error("No active session to update title");
                                return false;
                            }
                        }}
                        notification={notificationApi}
                    />
                    <div className="active-session-controls-container">
                        <ActiveSessionControls
                            className="active-session-controls"
                            session={session}
                            exportDisabled={sessionIsLoading(sessionStatus)}
                            modificationDisabled={
                                !session.allowModification(currentUser?.id) || isProjectTaskSession(session)
                            }
                            onPulledRemoteChanges={() => refreshTurns()}
                            notification={notificationApi}
                            modal={modalApi}
                            onClickProjectLink={onClickProjectLink}
                            isDemoRepo={activeRepo?.is_demo || false}
                        />
                        <div className="active-session-controls-divider small-screen-hidden" />
                        <span className="active-session-controls small-screen-hidden">
                            {sessionViewMode === "photo" && (
                                <Button onClick={handleScreenshot} type="text" icon={<CameraOutlined />} />
                            )}
                            <GlowingTooltip
                                configs={[
                                    {
                                        action: TutorialAction.HIGHLIGHT_CHANGES,
                                        title: "Switch views to see either your conversation with Solver or a clean summary of all code changes made",
                                        suppress: isMobileDevice,
                                    },
                                ]}
                                placement="bottomLeft"
                            >
                                <Radio.Group
                                    size="small"
                                    onChange={(e) => {
                                        setSessionViewMode((prevViewMode) => {
                                            if (prevViewMode === "chat") {
                                                setChatScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                            } else if (prevViewMode === "changes") {
                                                setChangesScrollPosition(sessionContentRef.current?.scrollTop || 0);
                                            }

                                            return e.target.value;
                                        });
                                    }}
                                    value={sessionViewMode}
                                    optionType="button"
                                    disabled={!session || turns.length === 0}
                                >
                                    <Radio.Button className="active-session-controls-radio" value="chat">
                                        Chat
                                    </Radio.Button>
                                    <Radio.Button className="active-session-controls-radio" value="changes">
                                        Changes
                                    </Radio.Button>
                                </Radio.Group>
                            </GlowingTooltip>
                        </span>
                    </div>
                </div>
                {turns.length > 0 && (
                    <div className={`session-content ${scrollbarStableBothClasses}`} ref={sessionContentRef}>
                        {sessionContent()}
                    </div>
                )}
                <div className="input-container">
                    <NaturalLanguageInput
                        notification={notificationApi}
                        editingTurn={editingTurn}
                        onCancelEditingTurn={() => setEditingTurn(null)}
                        sessionType={"solving"}
                    />
                </div>
                {contextHolder}
                {modalContextHolder}
            </Layout>
        );
    };

    switch (loadingSessionState) {
        case LoadingSessionState.ERROR:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Failed to load session</span>
                </Content>
            );
        case LoadingSessionState.NOT_FOUND:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">Session not found</span>
                </Content>
            );
        case LoadingSessionState.LOADING:
            return (
                <Content className="no-active-session">
                    <span className="no-active-session-text">
                        <LoadingOutlined style={{ fontSize: "40px" }} />
                    </span>
                </Content>
            );
        case LoadingSessionState.DONE:
            if (!session || (session && turns.length === 0)) {
                return buildWelcomeMessage();
            } else {
                return buildSessionContent();
            }
    }
};

export default ActiveSession;
