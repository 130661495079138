import React, { useState } from "react";
import { Input, List } from "antd";
import "./TechPlan.css";

export interface OpenQuestionsProps {
    questions: string[];
    onQuestionAnswered: (questionIndex: number, question: string, answer: string, isUnanswering: boolean) => void;
    title?: string;
    disabled?: boolean;
}

export const OpenQuestions: React.FC<OpenQuestionsProps> = ({
    questions,
    onQuestionAnswered,
    title,
    disabled = false,
}) => {
    const [answers, setAnswers] = useState<string[]>(new Array(questions.length).fill(""));
    const [savedAnswers, setSavedAnswers] = useState<string[]>(new Array(questions.length).fill(""));
    const [answeredIndexes, setAnsweredIndexes] = useState<Set<number>>(new Set());

    const handleAnswerChange = (index: number, value: string) => {
        setAnswers((prevAnswers) => prevAnswers.map((oldValue, i) => (i === index ? value : oldValue)));

        // If this was previously answered, mark as unanswered as soon as editing starts
        if (answeredIndexes.has(index)) {
            onQuestionAnswered(index, questions[index], "", true);
        }

        setAnsweredIndexes((prev) => {
            const newIndices = new Set(prev);
            newIndices.delete(index);
            return newIndices;
        });
    };

    const handleSubmitAnswer = (index: number) => {
        const answerText = answers[index];
        // Only save if there's actual content (including whitespace-only content)
        if (answerText !== "") {
            const newSavedAnswers = [...savedAnswers];
            newSavedAnswers[index] = answerText;
            setSavedAnswers(newSavedAnswers);

            setAnsweredIndexes((prev) => new Set(prev).add(index));
            onQuestionAnswered(index, questions[index], answerText, false);
        }
    };

    if (questions.length === 0) {
        return null;
    }

    return (
        <div className="tech-plan-questions">
            {title && <div className="tech-plan-task-section-title">{title}</div>}
            <List
                dataSource={questions}
                renderItem={(question, index) => (
                    <div key={index} className="tech-plan-question">
                        <div className="tech-plan-question-text">{question}</div>
                        <Input.TextArea
                            className={`tech-plan-answer-input ${answeredIndexes.has(index) ? "answered" : ""}`}
                            value={answers[index]}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            placeholder="Enter your answer..."
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            disabled={disabled}
                            onBlur={() => handleSubmitAnswer(index)}
                        />
                    </div>
                )}
            />
        </div>
    );
};
