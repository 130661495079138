import { createContext, useContextSelector } from "use-context-selector";
import qs from "qs";
import { AuthType, SessionVisibility } from "./User";
import { Session, SessionStatus, SessionStub } from "./SolverSession";
import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import { NavigationBehavior } from "./Navigation";

export interface ProjectStub {
    project_id: string;
    provider: AuthType;
    repo_name: string;
    planning_session_id: string;
    name: string;
    created_at: number;
    owner: string;
    user_name: string;
    user_avatar_url: string;
    base_revision: string | undefined;
    user_branch_name: string | undefined;
    visibility: SessionVisibility;
    execution_status: string;
    is_read_only: boolean;
}

export type ProjectInfo = {
    org: string;
    repo: string;
    project_id: string;
};

export interface Project extends ProjectStub {
    allowModification: (currentUserId?: string) => boolean;
    getInfo: () => ProjectInfo;
}

// Helper function to check project modification permissions
export const projectStubToProject = (projectStub: ProjectStub) => {
    const [org, repo] = projectStub.repo_name.split("/");

    return {
        ...projectStub,
        allowModification: (currentUserId?: string) => {
            if (projectStub.is_read_only) {
                return false;
            }
            if (projectStub.visibility === SessionVisibility.PUBLIC_READ_WRITE) {
                return true;
            }
            return projectStub.owner === currentUserId;
        },
        getInfo: () => ({ org, repo, project_id: projectStub.project_id }),
    };
};

export interface GetTechPlanResponse {
    plan: TechPlanData | undefined;
    is_executable: boolean;
    problem: string | undefined;
}

export interface TechPlanData {
    tasks: TechPlanTaskData[];
    open_questions: string[];
}

export interface TechPlanTaskData {
    id: string;
    summary: string;
    description: string;
    success_criteria: string;
    verification_plan: string;
    dependencies: string[];
    open_questions: string[];
}

export type PlanningAnswer = {
    task_id: string | null;
    question_idx: number;
    question: string;
    answer: string;
};

export interface CreateProjectRequest {
    name: string;
    visibility: SessionVisibility;
    ref?: string;
}

export interface CreateProjectAndPlanRequest {
    nl_text: string;
    name: string;
    visibility: SessionVisibility;
    ref?: string;
}

export interface PlanRequest {
    nl_text: string | null;
    open_question_answers: PlanningAnswer[];
}

export enum TaskType {
    STANDARD = "standard",
    MERGE_RESOLUTION = "merge_resolution",
}

export interface Task {
    project_task_id: string;
    project_execution_id: string;
    readable_id: string;
    summary: string;
    description: string;
    success_criteria: string;
    verification_plan: string;
    session_id: string | undefined;
    type: TaskType;
    created_at: number;
}

export interface TaskDependency {
    dependent_id: string;
    dependency_id: string;
}

export interface TaskEvaluation {
    task_id: string;
    session_id: string;
    success_criteria_met: boolean;
    verification_plan_met: boolean;
    indeterminate: boolean;
    source: string;
}

export enum ExecutionStatus {
    READY = "ready",
    RUNNING = "running",
    CANCELLING = "cancelling",
    // webapp only intermediate states while submiting requests.
    SUBMITTING_EXECUTION = "submitting_execution",
    SUBMITTING_CANCEL = "submitting_cancel",
    SUBMITTING_DELETE = "submitting_delete",
}

export interface ExecutionGraph {
    tasks: Task[];
    dependencies: TaskDependency[];
    task_sessions: SessionStub[];
    task_evaluations: TaskEvaluation[];
    cancelled: boolean;
    status: ExecutionStatus;
}

export enum LoadingProjectState {
    LOADING = "loading",
    DONE = "done",
    NOT_FOUND = "not_found",
    ERROR = "error",
}

export type ProjectContextType = {
    project: Project | undefined;
    techPlanData: TechPlanData | undefined;
    techPlanOpenQuestionsCount: number | undefined;
    techPlanProblem: string | undefined;
    executionGraph: ExecutionGraph | undefined;
    executionStatus: ExecutionStatus | undefined;
    planningSession: Session | undefined;
    planningSessionStatus: SessionStatus | undefined;
    loadingProjectState: LoadingProjectState;
    loadProject: (ProjectInfo: ProjectInfo | undefined, navigationBehavior: NavigationBehavior) => Promise<void>;
    updateProject: (project: Project, planningSession: Session, executionGraph: ExecutionGraph) => Promise<void>;
    createProjectAndPlan: (org: string, repo: string, request: CreateProjectAndPlanRequest) => Promise<Project>;
    evaluateTaskSession: (projectTaskId: string, sessionId: string) => Promise<boolean>;
    canExecuteProject: boolean;
    executionDisabledMessage: string;
    executeProject: (numSteps: number) => Promise<boolean>;
    canCancelProjectExecution: boolean;
    cancelProjectExecution: () => Promise<boolean>;
    canDeleteProjectExecution: boolean;
    deleteProjectExecution: () => Promise<boolean>;
};

const nullProjectContext: ProjectContextType = {
    project: undefined,
    techPlanData: undefined,
    techPlanOpenQuestionsCount: undefined,
    techPlanProblem: undefined,
    executionGraph: undefined,
    executionStatus: undefined,
    planningSession: undefined,
    planningSessionStatus: undefined,
    updateProject: async () => {},
    loadProject: async () => {},
    loadingProjectState: LoadingProjectState.DONE,
    createProjectAndPlan: async () => {
        throw new Error("Project context not initialized");
    },
    evaluateTaskSession: async () => Promise.resolve(false),
    canExecuteProject: false,
    executionDisabledMessage: "",
    executeProject: async () => Promise.resolve(false),
    canCancelProjectExecution: false,
    cancelProjectExecution: async () => Promise.resolve(false),
    canDeleteProjectExecution: false,
    deleteProjectExecution: () => Promise.resolve(false),
};

export const ProjectContext = createContext<ProjectContextType>(nullProjectContext);

export const useProject = () => useContextSelector(ProjectContext, (ctx) => ctx.project);
export const useTechPlanData = () => useContextSelector(ProjectContext, (ctx) => ctx.techPlanData);
export const useTechPlanOpenQuestionsCount = () =>
    useContextSelector(ProjectContext, (ctx) => ctx.techPlanOpenQuestionsCount);
export const useTechPlanProblem = () => useContextSelector(ProjectContext, (ctx) => ctx.techPlanProblem);
export const useExecutionGraph = () => useContextSelector(ProjectContext, (ctx) => ctx.executionGraph);
export const useExecutionStatus = () => useContextSelector(ProjectContext, (ctx) => ctx.executionStatus);
export const usePlanningSession = () => useContextSelector(ProjectContext, (ctx) => ctx.planningSession);
export const usePlanningSessionStatus = () => useContextSelector(ProjectContext, (ctx) => ctx.planningSessionStatus);
export const useLoadingProjectState = () => useContextSelector(ProjectContext, (ctx) => ctx.loadingProjectState);
export const useLoadProject = () => useContextSelector(ProjectContext, (ctx) => ctx.loadProject);
export const useUpdateProject = () => useContextSelector(ProjectContext, (ctx) => ctx.updateProject);
export const useCreateProjectAndPlan = () => useContextSelector(ProjectContext, (ctx) => ctx.createProjectAndPlan);
export const useEvaluateTaskSession = () => useContextSelector(ProjectContext, (ctx) => ctx.evaluateTaskSession);
export const useExecuteProject = () => useContextSelector(ProjectContext, (ctx) => ctx.executeProject);
export const useCanExecuteProject = () => useContextSelector(ProjectContext, (ctx) => ctx.canExecuteProject);
export const useExecutionDisabledMessage = () =>
    useContextSelector(ProjectContext, (ctx) => ctx.executionDisabledMessage);
export const useCanCancelProjectExecution = () =>
    useContextSelector(ProjectContext, (ctx) => ctx.canCancelProjectExecution);
export const useCancelProjectExecution = () => useContextSelector(ProjectContext, (ctx) => ctx.cancelProjectExecution);
export const useCanDeleteProjectExecution = () =>
    useContextSelector(ProjectContext, (ctx) => ctx.canDeleteProjectExecution);
export const useDeleteProjectExecution = () => useContextSelector(ProjectContext, (ctx) => ctx.deleteProjectExecution);

export const getProjectBase = async (projectInfo: ProjectInfo): Promise<Project> => {
    const response = await solverInterfaceApiAxios.get<ProjectStub>(
        `/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}`
    );

    return projectStubToProject(response.data);
};

export const getProjectTechPlan = async (projectInfo: ProjectInfo): Promise<GetTechPlanResponse> => {
    return (
        await solverInterfaceApiAxios.get<GetTechPlanResponse>(
            `/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}/tech_plan`
        )
    ).data;
};

export const getProjectExecution = async (projectInfo: ProjectInfo): Promise<ExecutionGraph> => {
    return (
        await solverInterfaceApiAxios.get<ExecutionGraph>(
            `/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}/execution`
        )
    ).data;
};

export const evaluateProjectTaskSession = async (
    projectInfo: ProjectInfo,
    projectTaskId: string,
    sessionTaskId: string
): Promise<boolean> => {
    try {
        // TODO: On the backend, this endpoint should not require a session to be provided.
        await solverInterfaceApiAxios.post(
            `/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}/execution/tasks/${projectTaskId}/evaluation`,
            { session_id: sessionTaskId }
        );

        return true;
    } catch (error) {
        return false;
    }
};

export const createProject = async (org: string, repo: string, request: CreateProjectRequest): Promise<Project> => {
    try {
        const response = await solverInterfaceApiAxios.post(`/repos/${org}/${repo}/projects`, request);
        return projectStubToProject(response.data);
    } catch (error) {
        throw error;
    }
};

export const deleteProject = async (projectInfo: ProjectInfo): Promise<boolean> => {
    return solverInterfaceApiAxios
        .delete(`/repos/${projectInfo.org}/${projectInfo.repo}/projects/${projectInfo.project_id}`)
        .then(() => true)
        .catch(() => false);
};

export const getProjectsList = async (
    org: string,
    repo: string,
    titleFilter: string,
    sortByCreated: boolean,
    sortAscending: boolean,
    page: number,
    pageSize: number
): Promise<Project[]> => {
    const params = {
        title: titleFilter,
        sort_by: sortByCreated ? "created_at" : "name",
        sort_order: sortAscending ? "asc" : "desc",
        page: page,
        page_size: pageSize,
    };

    const response = await solverInterfaceApiAxios.get(`/repos/${org}/${repo}/projects?${qs.stringify(params)}`);
    return response.data.map(projectStubToProject);
};

export { ProjectProvider } from "./ProjectProvider";
